var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("time-picker-field " + (_vm.field.dense ? 'dense' : ''))},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{class:{
      'v-input v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder time-picker-field': true,
      'dense': _vm.field.dense,
      'error--text v-input--has-state': _vm.getError() && _vm.getError().has
    }},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_vm._m(0),_c('div',{staticClass:"v-text-field__slot"},[_c('label',{class:{
            'v-label v-label--active theme--light': true,
            'error--text': _vm.getError() && _vm.getError().has
          },staticStyle:{"left":"0px","right":"auto","position":"absolute"},domProps:{"textContent":_vm._s(_vm.field.title)}}),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(/\d{2}:\d{2}/),expression:"/\\d{2}:\\d{2}/"},{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],attrs:{"autocomplete":"off","placeholder":"vv:mm","type":"text"},domProps:{"value":(_vm.value)},on:{"keyup":function($event){return _vm.onInputChange($event)},"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}})])]),_vm._m(1)])]):_c('v-text-field',{class:("time-picker-field " + (_vm.field.dense ? 'dense' : '')),attrs:{"type":"time","value":_vm.value,"disabled":typeof _vm.field.disabled === 'boolean' ? _vm.field.disabled : _vm.field.disabled(),"outlined":"","label":_vm.field.title,"hide-details":_vm.field.hideDetails,"dense":_vm.field.dense,"autocomplete":_vm.form.autocomplete,"placeholder":"vv:mm","readonly":_vm.readOnly,"error":_vm.getError() && _vm.getError().has,"error-count":_vm.getError() && _vm.getError().count,"error-messages":_vm.getError() && _vm.getError().messages},on:{"update:value":function($event){_vm.value=$event},"change":_vm.changeValue,"focusout":_vm.setMinutes}}),_c('v-messages',{attrs:{"value":_vm.getError() && _vm.getError().messages,"color":"error"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{attrs:{"aria-hidden":"true"}},[_c('legend',[_c('span',[_vm._v("​")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])}]

export { render, staticRenderFns }