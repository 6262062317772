

















































import { Component, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import __ from '@/shared/helpers/__'
import _ from 'lodash'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
// @ts-ignore
import mask from 'vue-r-mask'

@Component({
    components: { VueTimepicker },
    methods: { __ },
    directives: { mask }
  })
  export default class TimePickerField extends AbstractField {
    timePickerValue: string = ''
    menuItem: boolean = false
    readOnly: boolean = false
    @Watch('timePickerValue') private handleTimePickerChange(): void {
      this.value = this.timePickerValue
      this.removeError()
      if (this.field.onChange) this.field.onChange()
    }
    @Watch('value') private onValueChange(): void {
      this.timePickerValue = this.value
    }

    created() {
      this.timePickerValue = this.value
    }

    onClose(): void {
      this.menu = false
      this.readOnly = false
    }

    setMinutes(): void {
      if (this.value.length > 0 && this.value.length <= 3) {
        let hours = this.value.split(':')[0]
        if (hours.length === 1) hours = `0${ hours }`
        this.value = `${ hours }:00`
      }
    }

    changeValue(value: any) {
      this.value = value
      this.removeError()
    }

    onInputChange(event: any): void {
      if (event?.target?.value === '__:__') this.value = null
    }

    get min(): string|null {
      if (this.field.meta && this.field.meta.hasOwnProperty('minKey')) {
        const fullKey = this.fullKey.split('.')
          .filter((value: string) => value !== this.field.key)
          .join('.')
        return _.get(this.form.data, this.field.meta.minKey(fullKey), null)
      }

      return null
    }

    get menu(): boolean {
      return this.menuItem
    }

    set menu(value: boolean) {
      this.menuItem = value
    }
  }
